<template>
  <PageWithLayout>
    <ProgressStep
      :total="model.infomationStepData.length"
      :step="realIndex+1"
      :speed="swiperInfoOption.autoplay.delay + swiperInfoOption.speed" />
    <swiper
      class="swiper_info"
      :options="swiperInfoOption"
      ref="infoSwiper"
      @slideChange="slideChange()">
      <swiper-slide
        v-for="(item, index) in model.infomationStepData"
        :key="`infoStep${index+1}`">
        <InfomationLayout
          :stepData="item"
          :index="index+1" />
      </swiper-slide>
    </swiper>
    <div class="foot_lemontree">
      <div class="inner_foot">
        <div class="group_btn">
          <Button
            v-if="realIndex != 0"
            btnStyle="secondary_tonal"
            text="이전"
            @onClickBtn="onClickPrev()" />
          <Button
            v-if="realIndex+1 != model.infomationStepData.length"
            btnStyle="secondary_tonal"
            text="다음"
            @onClickBtn="onClickNext()" />
          <Button
            v-if="realIndex+1 === model.infomationStepData.length"
            btnStyle="primary"
            text="완료"
            @onClickBtn="onClickEnd()" />
        </div>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import ProgressStep from '@/components/common/progress/ProgressStep';
import Button from '@/components/common/button/Button';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import InfomationLayout from '@/views/card/view/components/infomation/InfomationLayout';
// model
import CardInfomationModel from '@/views/card/model/CardInfomationModel';

// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

export default {
  name: 'CardInfomation',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    ProgressStep,
    Button,
    Swiper,
    SwiperSlide,
    InfomationLayout
  },
  mounted(){
    if(this.$refs.infoSwiper && this.$refs.infoSwiper.$swiper){
      this.$nextTick(() => {
        this.setRealIndex();
      });
    }
  },
  beforeMount(){
    this.isQRweb = this.$route.name === 'CardInfomationWeb';
    this.swiperInfoOption.initialSlide = this.isQRweb ? 0 : 1;
  },
  data(){
    return{
      isQRweb: false,
      model: new CardInfomationModel(),
      realIndex:-1,
      swiperInfoOption:{
        initialSlide: 0,
        effect : 'fade',
        allowTouchMove:true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
          stopOnLastSlide: true
        },
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 500,
        loop: false,
      }
    }
  },
  methods:{
    slideChange(){
      this.setRealIndex();
    },
    setRealIndex(){
      const realIndex = this.$refs.infoSwiper.$swiper.realIndex;
      this.realIndex = realIndex;
    },
    onClickPrev(){
      this.$refs.infoSwiper.$swiper.slidePrev();
    },
    onClickNext(){
      this.$refs.infoSwiper.$swiper.slideNext();
    },
    onClickEnd(){
      if(this.isQRweb){
        window.open('https://www.firfin.family', '_self');
      }else{
        this.webEnd();
      }
    },
    onClickBackKey(){
      if(this.realIndex > 0){
        this.onClickPrev();
      }else{
        this.webEnd();
      }
    }
  }
}
</script>

<style scoped>
.swiper_info{width:100%;height:100%}

.progress_step,
.foot_lemontree{padding:0 20px}

.progress_step{flex:0 1}

.foot_lemontree{flex:0 1;position:relative;z-index:100}
.inner_foot{margin-top:-20px;padding:20px 0 32px;background:none;background-image:linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 9.9%)}
</style>
