
export default class CardInfomationModel {
  constructor(){
    this.infomationStepData = [
      {
        title: {
          front: ``,
          primary: `퍼핀앱에 카드를 등록`,
          end: `해야<br/>결제할 수 있어요`,
        },
        desc: `카드 수령 후 퍼핀앱에 자녀로 로그인하고<br/>‘카드 등록하고 용돈쓰기' 버튼으로 카드를<br/>등록해주세요.`,
        img:{
          'max-height':'380px',
          'background':'url(/assets/images/card_info/img_step1_240611.png) center bottom / contain no-repeat',
          'bottom':0,
        },
      },
      {
        title: {
          front: `카드 뒷면의 <br/>교통카드 브랜드 확인 후<br/>`,
          primary: `할인등록`,
          end: `해주세요`,
        },
        desc: `이즐(캐시비) 또는 레일플러스에서 <br/>할인등록해야 어린이/청소년 요금이 적용돼요.`,
        button:[{
          btnStyle: 'primary_deep_purple',
          btnSize: 'small',
          text: '레일+ 홈페이지',
          routerUrl: 'https://railplus.korail.com/com/mic/set/hps/crd/registNonmemberCardForm.do'
        },{
          btnStyle: 'primary_deep_purple',
          btnSize: 'small',
          text: '이즐 홈페이지',
          routerUrl: 'https://www.cashbee.co.kr/cb/register/kidDcRegView.do'
        }],
        wrapImg:{
          margin:'24px 0'
        },
        img:{
          'max-height':'210px',
          'background':'url(/assets/images/card_info/img_step2_241127.png) center 0px / contain no-repeat',
          'top':'50%',
          'transform': 'translate(0, -50%)'
        },
        // tip: `비회원 등록도 괜찮아요. 등록한 정보 수정 문의는 레일플러스에 해주세요.`,
      },
      {
        title: {
          front: ``,
          primary: `교통카드`,
          end: `는<br/>별도로 충전해주세요`,
        },
        desc: `편의점, 수도권 지하철 충전기, 이즐 또는 레일플러스 가맹점에서 충전 가능해요.<br/>교통카드 브랜드 홈페이지를 참고해주세요.`,
        img:{
          'max-height':'204px',
          'background':'url(/assets/images/card_info/img_step3_241127.png) center 0px / contain no-repeat',
          'top':0,
        },
        // tip: `퍼핀앱에서 충전 및 잔액 보기 기능은 23년 하반기에 출시 예정이에요.`,
      },
      {
        title: {
          front: ``,
          primary: `네이버페이, ISP`,
          end: `로<br/>온라인 결제 가능해요`,
        },
        desc: `네이버페이 · ISP/페이북 · 카카오페이에 등록하면 온라인 결제 또는 모바일로 결제할 수 있어요.`,
        img:{
          'max-height':'227px',
          'background':'url(/assets/images/card_info/img_step4_240611.png) center 0px / contain no-repeat',
          'top':0,
        },
        tip: `온라인/모바일 결제 방법 자세한 안내는 고객센터를 확인해주세요.`,
      },
      {
        title: {
          front: `카드 `,
          primary: `분실신고`,
          end: `는<br/>앱에서 즉시 할 수 있어요`,
        },
        desc: `부모님과 자녀 모두 분실신고를 할 수 있으니 분실 즉시 꼭 신고해주세요.`,
        img:{
          'max-height':'384px',
          'background':'url(/assets/images/card_info/img_step5.png) center bottom / contain no-repeat',
          'bottom':0,
        },
      },
      {
        title: {
          front: `하루 50만원까지<br/>`,
          primary: `KB 국민카드 가맹점`,
          end: ` 어디서든<br/>쓸 수 있어요`,
        },
        desc: `단, 청소년 유해업종 결제와 현금성 상품권 구매는 불가능해서 안전해요.`,
        img:{
          'max-height':'320px',
          'background':'url(/assets/images/card_info/img_step6_241127.png) center 0px / contain no-repeat',
          'top':'50%',
          'transform': 'translate(0, -50%)'
        },
      },
    ];
  }
}